export default {
  // The keys defined here will override those in the base locale file, for example:
  // 'theme_vendor': 'D-BAT'
  theme_vendor: 'Own the Zone Sports Academy',
  'customers.TermsDialog.accept':
    'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName, select, theme_vendor {} other {{customerName} and}} Upper Hand Inc.',
  'client_theme.ThemeSidebar.subHeader': 'Welcome',
  'client_theme.ThemeSidebar.mission':
    "Own the Zone Sports Academy is a state-of-the-art health first sports facility, where wellness meets performance! Whether you're a seasoned athlete, a fitness enthusiast, or just embarking on your wellness journey, we're thrilled to have you here. Get ready to elevate your skills and unlock your potential. From top-notch equipment to expert coaching, we're here to help you reach your goals and make every workout count.",
  'client_theme.ThemeSidebar.learn_more': 'Learn More', // leave a space here so we don't display this for main instance.
};
