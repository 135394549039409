export default {
  // The keys defined here will override those in the base locale file, for example:
  // fontFamily: 'Courier New'
  name: 'Own The Zone Sports Academy',
  siteURL: 'https://otzsa.com/',
  logo: {
    url: 'https://s3.amazonaws.com/upperhand-app/static/images/otz_logo.png',
    width: 325,
  },
  login: {
    desktopSignInColor: '#FF0000',
    mobileSignInColor: '#FF0000',
  },
  palette: {
    // TODO: remove primary1Color and accent1Color replaace int usages with a new one
    primary1Color: '#FF0000',
    accent1Color: '#000',

    primary: {
      main: '#FF0000',
    },
    secondary: {
      main: '#000',
    },
  },
  sideNav: {
    selectedColor: '#FF0000',
  },
  icons: {
    cart: '#FF0000',
  },
  statusBar: {
    backgroundColor: '#FF0000',
  },
};
